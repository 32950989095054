@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

/* Custom scrollbar styles */
.custom-scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #e2e8f0;
}

/* For webkit-based browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e2e8f0;
  border-radius: 4px;
}

.date::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(50%) sepia(100%) saturate(10000%)
    hue-rotate(180deg);
}

/* add city button    */

.add-city {
  background-image: linear-gradient(
    to right,
    #00c6ff 0%,
    #0072ff 51%,
    #00c6ff 100%
  );
  margin: 10px;
  padding: 5px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 50px;
  display: block;
  font-size: 15px;
  position: relative;
  top: -20px;
  text-wrap: nowrap;
}

.add-city:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.add-city-inner {
  background-image: linear-gradient(
    to right,
    #00c6ff 0%,
    #0072ff 51%,
    #00c6ff 100%
  );
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  display: block;
  font-size: 15px;
  border-radius: 5px;
  /* text-wrap: nowrap; */
}

.add-city-inner:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

input[type="tel"] {
  background-color: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 15px;
}

/* Hide the spinner for number inputs in WebKit browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* Remove spinner */
  margin: 0;
  /* Remove default margin */
}

/* Optionally, you can also apply styles to the input */
input[type="number"] {
  /* Your additional styles here */
  width: 100%;
  /* Example width */
  padding: 8px;
  /* Example padding */
  /* Example border */
  border-radius: 4px;

  /* pointer-events: none; Prevent mouse wheel events */
}

/* Hide the button with aria-label="Show/Hide filters" */
button[aria-label="Show/Hide filters"] {
  display: none !important;
}
/* .disable-scroll {
  pointer-events: none; 
} */
/* .apexcharts-pie {
  cursor: pointer;
} */

.Toastify__toast-body {
  align-items: start !important;
}

.bubble {
  --r: 1em; /* the radius */
  --t: 1.5em; /* the size of the tail */

  max-width: 300px;
  padding: 0.3em 1em;
  border-inline: var(--t) solid #0000;
  border-radius: calc(var(--r) + var(--t)) / var(--r);
  mask: radial-gradient(100% 100% at var(--_p) 0, #0000 99%, #000 102%)
      var(--_p) 100% / var(--t) var(--t) no-repeat,
    linear-gradient(#000 0 0) padding-box;
  color: #fff;
}
.left {
  --_p: 0;
  border-bottom-left-radius: 0 0;
  place-self: start;
  float: left;
  background: linear-gradient(135deg, #828282, #4d4e4e) border-box;
}
.right {
  --_p: 100%;
  border-bottom-right-radius: 0 0;
  place-self: end;
  background: linear-gradient(135deg, #35f909c3, #2c8413) border-box;
}
